import React from "react";
import KBrochure from "./kBrochure";

const Main = () => {
  return (
   <KBrochure/>
  );
};

export default Main;

