import React from 'react';
import ContactComponent from '../components/main/contactCompo';



const Contact = () => {
    return (
        <>
         <ContactComponent/>
        </>
       
    );
};

export default Contact;